<template>
  <div>
    <div class="item" @click="gotoemit">
   <img :src="pic" />
      <h4>{{ themetitle }}</h4>
      <div class="brief">
        <div>作者：{{ authorzz }}</div>
        <div class="company">单位：{{ company }}</div>
      </div>
      <i class="iconfont icon-tuxing"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "Item",
  // 接受父组件的值
  props: {
    themetitle: {
      type: String,
      required: true,
    },
    authorzz: {
      type: String,
      required: true,
    },
    company: {
      type: String,
      required: true,
    },
    pic: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },
  methods: {
    gotoemit() {
      this.$emit("gotoclick");
    },
  },
};
</script>
<style scoped>
.icon-tuxing:before {
  font-size: 50px;
  color: #067fd8;
  float: right;
  margin-right: 15px;
  margin-top: -10px;
}
</style>
<style scoped lang="scss">
.item {
  margin-top: 20px;
  width: 384px;
  background: #fff;
  height: 410px;
  margin-left: 20px;
  cursor: pointer;
  img {
    width: 100%;
    height: 285px;
    object-fit: cover;
  }
  h4 {
    font-size: 24px;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 5px;
  }
  .brief {
    font-size: 16px;
    color: #999999;
    padding-left: 20px;
    display: flex;
    padding-top: 10px;
    .company {
      padding-left: 30px;
    }
  }
}
</style>